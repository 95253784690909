import React from 'react';

const Search = () => {

    return (
        <h1>Search</h1>

    );
};

export default Search;