import React from 'react';
import ButtonMailto from "./mail-to-button";
import FindMe from "./find-me";

const Contact = () =>
    <div id={'contact'}>
        <br/>
        <br/>
        <h2>Contact</h2>
        <FindMe/>
    </div>

export default Contact